import axios from 'axios';
import HttpStatus from '~/commons/constants/HttpStatus';
import { ROUTE_SIGN_IN } from '~/constants/route-path';

const API_SERVER = process.env.REACT_APP_API_BASE_URL || '';
const API_TIME_OUT = Number(process.env.REACT_APP_API_TIME_OUT) || 0;

const MEDIA_TYPE = {
  JSON: 'application/json',
};

const AXIOS_CONFIG = {
  baseURL: API_SERVER,
  headers: {
    'Content-Type': `${MEDIA_TYPE.JSON}; charset=utf-8`,
    Accept: MEDIA_TYPE.JSON,
  },
  timeout: API_TIME_OUT,
  withCredentials: true,
};

const TOKEN_REISSUE_URL = '/token/reissue';

export default class Api {
  constructor() {
    this.axiosInstance = axios.create(AXIOS_CONFIG);

    this.axiosInstance.interceptors.request.use((config) => {
      if (!window.navigator.onLine) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject({
          statue: HttpStatus.BAD_REQUEST,
          message: '인터넷이 연결되어있지 않습니다. 연결상태를 확인해주세요.',
          errors: [],
        });
      }

      const accessToken = localStorage.getItem('accessToken');
      if (accessToken) {
        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
      return config;
    });

    this.axiosInstance.interceptors.response.use(
      ({ data, headers, status, config }) => ({
        data,
        headers,
        status,
        config,
      }),
      (error) => {
        let errResponse;
        if (!error.response) {
          const message = error.message === 'Network Error' ? '서버오류입니다.' : error.message;
          errResponse = {
            status: HttpStatus.INTERNAL_SERVER_ERROR,
            message,
            code: null,
            errors: [],
            isError: true,
          };
        } else {
          const { status, message, code } = error.response.data;
          errResponse = { status, message, code, errors: error.response.data.errors, isError: true };

          if (status === 401) {
            const accessToken = localStorage.getItem('accessToken');

            if (this._isReissueCondition(error, accessToken)) {
              return this._reissue(error, accessToken);
            } else {
              localStorage.removeItem('accessToken');
              // history.push(PATH_URL.LOGIN);
              // location = ROUTE_SIGN_IN;
            }
          }
        }
        return errResponse;
      },
    );
  }

  _tryReissue(error) {
    const accessToken = localStorage.getItem('accessToken');
    if (this._isReissueCondition(error, accessToken)) {
      return this._reissue(error, accessToken);
    } else {
      localStorage.removeItem('accessToken');
      // history.push(PATH_URL.LOGIN);
      // location = ROUTE_SIGN_IN;
    }
  }

  _isReissueCondition(error, accessToken) {
    return (
      error.request.responseURL !== error.config.baseURL + TOKEN_REISSUE_URL &&
      !!accessToken &&
      accessToken !== 'undefined'
    );
  }

  _reissue(error, accessToken) {
    return this.axiosInstance
      .post(TOKEN_REISSUE_URL, {
        accessToken,
      })
      .then((res) => {
        const { accessToken: reissuedAccessToken } = res.data.data;
        localStorage.setItem('accessToken', reissuedAccessToken);
        error.config.headers.Authorization = `Bearer ${reissuedAccessToken}`;
        return this.axiosInstance.request(error.config);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  get(uri, data, options) {
    return this.axiosInstance.get(uri, { params: data, ...options });
  }

  put(uri, data) {
    return this.axiosInstance.put(uri, data);
  }

  patch(uri, data) {
    return this.axiosInstance.patch(uri, data);
  }

  delete(uri, data) {
    return this.axiosInstance.delete(uri, data);
  }

  post(uri, data, options) {
    return this.axiosInstance.post(uri, data, { headers: { ...options } });
  }
}

export const apiInstance = new Api();
