import React, { useEffect, useRef } from 'react';

import '~/commons/css/header.css';

import MainLogoImg from 'Asset/image/logo_main@2x.png';
import MenuImg from 'Asset/image/menu@2x.png';
import SearchImg from 'Asset/image/search@2x.png';

export function Header() {
  return (
    <div
      id="header-wrapper"
      onClick={() => {
        window.scrollTo(0, 0);
      }}>
      <div className="row">
        <a className="menu" href={'/'}>
          <img className="fl main-logo" src={MainLogoImg} />
        </a>
        <a className="menu">
          <img className="fr btn" src={MenuImg} />
        </a>
        <a className="menu">
          <img className="fr btn" src={SearchImg} />
        </a>
      </div>
    </div>
  );
}
