import React, { useEffect, useRef } from 'react';

import { Header } from '~/commons/components/Header';

export function MainTemplate({ children, overflow }) {
  return (
    <div>
      <Header />
      <div className="no-scroll" style={{ marginTop: '46px', overflow: overflow ? overflow : 'none' }}>
        {children}
      </div>
    </div>
  );
}
