import React, { useEffect } from 'react';

import '~/commons/css/webtoonView.css';

import { useNavigate } from 'react-router';
import { NavLink } from 'react-router-dom';

import { MainTemplate } from '../commons/components/MainTemplate';

import RightArrowImg from 'Asset/image/right_arrow@2x.png';
import LefrArrowImg from 'Asset/image/left_arrow@2x.png';
import BackImg from 'Asset/image/back@2x.png';
import CommentImg from 'Asset/image/comment@2x.png';
import { WebtoonService } from '~/network/service/WebtoonService';

export function WebtoonView() {
  const navigate = useNavigate();
  const [webtoon, setWebtoon] = React.useState();
  const [episode, setEpisode] = React.useState();
  const [totalEpisodeCount, setTotalEpisodeCount] = React.useState();
  const [isLoading, setLoading] = React.useState(false);

  const fetchWebtoonEpisode = React.useCallback(async () => {
    setLoading(true);
    const episode = window.location.pathname.split('/').pop();
    const webtoonId = window.location.pathname.split('/')[window.location.pathname.split('/').length - 2];
    const res = await WebtoonService.getWebtoonEpisode({ webtoonId, episode });
    if (res.data && res.data.data) {
      setWebtoon(res.data.data);
      setEpisode(episode);
    } else if (res.message) {
      alert(res.message);
    }

    const res2 = await WebtoonService.getWebtoon({ webtoonId });
    if (res2.data && res2.data.data) {
      setTotalEpisodeCount(res2.data.data.episodeCount);
    } else if (res.message) {
      alert(res.message);
    }

    setLoading(false);
  }, []);

  useEffect(() => {
    fetchWebtoonEpisode();
  }, [window.location.href]);

  return (
    <div id="webtoon-view-page">
      <div className="header">
        <a
          onClick={() => {
            const webtoonId = window.location.pathname.split('/')[window.location.pathname.split('/').length - 2];
            navigate(`/webtoon/${webtoonId}`);
          }}
          className="back">
          <img src={BackImg} />
        </a>
        <div className="title">{webtoon && webtoon.title}</div>
      </div>
      {!isLoading && (
        <div className="content-wrapper">
          {webtoon &&
            Array.from({ length: webtoon.contentCount }, () => 0).map((v, index) => {
              return (
                <div key={index} className="content">
                  <img src={`${webtoon.contentPath}/${index + 1}${webtoon.extension}`} />
                </div>
              );
            })}
        </div>
      )}
      <div className="footer">
        <a className="comment">
          <img src={CommentImg} />
          &nbsp;&nbsp;{webtoon && webtoon.commentCount ? webtoon.commentCount : '0'}
        </a>

        <div className="navigation">
          {totalEpisodeCount && webtoon && webtoon.episode > 1 && (
            <a
              className="prev"
              onClick={() => {
                const episode = window.location.pathname.split('/').pop();
                let to = window.location.pathname.substring(0, window.location.pathname.length - episode.length);
                to += Number(episode) - 1;
                navigate(to);
              }}>
              <img src={LefrArrowImg} />
              &nbsp;&nbsp;이전화
            </a>
          )}
          &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
          {totalEpisodeCount && webtoon && webtoon.episode < totalEpisodeCount && (
            <a
              className="next"
              onClick={() => {
                const episode = window.location.pathname.split('/').pop();
                let to = window.location.pathname.substring(0, window.location.pathname.length - episode.length);
                to += Number(episode) + 1;
                navigate(to);
              }}>
              다음화&nbsp;&nbsp;
              <img src={RightArrowImg} />
            </a>
          )}
        </div>
      </div>
    </div>
  );
}
