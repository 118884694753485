import { apiInstance } from '~/network/Api';

export const WebtoonService = {
  getWebtoon: async (params) => {
    if (!params) return;
    const res = await apiInstance.get(`/webtoon/${params.webtoonId}`);
    return res;
  },

  getWebtoonList: async (params) => {
    if (!params) return;
    const res = await apiInstance.get(`/webtoon/list?page=${params.page}&size=${params.size}`);
    return res;
  },

  getWebtoonEpisodeList: async (params) => {
    if (!params) return;
    const res = await apiInstance.get(`/webtoon/episode/${params.webtoonId}`);
    return res;
  },

  getWebtoonEpisode: async (params) => {
    if (!params) return;
    const res = await apiInstance.get(`/webtoon/episode/${params.webtoonId}/${params.episode}`);
    return res;
  },
};
