import React, { useEffect } from 'react';

import '~/commons/css/webtoon.css';

import { useNavigate } from 'react-router';
import { NavLink } from 'react-router-dom';

import { MainTemplate } from '../commons/components/MainTemplate';

import BackWhiteImg from 'Asset/image/back_white@2x.png';
import PointImg from 'Asset/image/point@2x.png';
import { WebtoonService } from '~/network/service/WebtoonService';

export function Webtoon() {
  const navigate = useNavigate();
  const [webtoonEpisodeList, setWebtoonEpisodeList] = React.useState();
  const [isLoading, setLoading] = React.useState(false);

  const fetchWebtoonEpisodeList = React.useCallback(async () => {
    setLoading(true);
    const webtoonId = window.location.pathname.split('/').pop();
    const res = await WebtoonService.getWebtoonEpisodeList({ webtoonId });
    if (res.data && res.data.data) {
      setWebtoonEpisodeList(res.data.data);
    } else if (res.message) {
      alert(res.message);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchWebtoonEpisodeList();
  }, []);

  return (
    <div id="webtoon-page">
      {/* 배너 */}
      {webtoonEpisodeList && (
        <div
          className="banner"
          style={{
            backgroundImage: `url(${webtoonEpisodeList.webtoon.thumbnailUrl})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            width: '100vw',
          }}>
          <a
            onClick={() => {
              navigate('/');
            }}
            className="back">
            <img src={BackWhiteImg} />
          </a>
          <div className="content-wrapper">
            <div className="title">{webtoonEpisodeList.webtoon.title}</div>
            <div className="description">{webtoonEpisodeList.webtoon.description}</div>
            <div className="writer">{webtoonEpisodeList.webtoon.writer}</div>
            <div className="line" />
            <div className="info">
              <div className="category">{`${webtoonEpisodeList.webtoon.category1} · ${webtoonEpisodeList.webtoon.category2}`}</div>
            </div>
          </div>
        </div>
      )}
      {/* 리스트 */}
      <div className="list">
        {webtoonEpisodeList &&
          webtoonEpisodeList.episodeList &&
          webtoonEpisodeList.episodeList.map((episode, index) => {
            return (
              <NavLink
                key={index}
                className="webtoon-wrapper"
                to={`/webtoon/view/${webtoonEpisodeList.webtoon.id}/${episode.episode}`}>
                <div className="thumbnail">
                  <img src={episode.thumbnailUrl} />
                </div>
                <div className="info">
                  <div className="title">{episode.title}</div>
                  <div className="date">{episode.releaseDate && episode.releaseDate.substring(0, 10)}</div>
                </div>
                <div className="point">
                  {episode.point}
                  <img src={PointImg} />
                </div>
              </NavLink>
            );
          })}
      </div>

      <NavLink className="go" to={`/webtoon/view/${webtoonEpisodeList && webtoonEpisodeList.webtoon.id}/1`}>
        1화 보기
      </NavLink>
    </div>
  );
}
