import React, { useEffect, useRef } from 'react';

import '~/commons/css/main.css';

import { useNavigate } from 'react-router';
import { NavLink } from 'react-router-dom';

import { MainTemplate } from '../commons/components/MainTemplate';

import NewImg from 'Asset/image/new@2x.png';
import UpImg from 'Asset/image/up@2x.png';
import MainLogoImg from 'Asset/image/logo_main@2x.png';
import MenuImg from 'Asset/image/menu@2x.png';
import SearchImg from 'Asset/image/search@2x.png';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { WebtoonService } from '~/network/service/WebtoonService';

const TAB_INDEX = {
  RANKING: 0,
  NEW: 1,
  STORAGE: 2,
};

export function Main() {
  const navigate = useNavigate();
  const menuTabRef = useRef();
  const listRef = useRef();
  const [webtoonList, setWebtoonList] = React.useState([]);
  const [bannerWebtoonList, setBannerWebtoonList] = React.useState([]);
  const [selectedTab, setSelectedTab] = React.useState(TAB_INDEX.RANKING);
  const [page, setPage] = React.useState(0);
  const [size, setSize] = React.useState(20);
  const [isLoading, setLoading] = React.useState(false);

  const fetchWebtoonList = React.useCallback(async () => {
    setLoading(true);
    const res = await WebtoonService.getWebtoonList({ page, size });
    if (res.data && res.data.data?.content) {
      setWebtoonList(res.data.data?.content);
      setBannerWebtoonList(res.data.data?.content);
    } else if (res.message) {
      alert(res.message);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchWebtoonList(page);
  }, [page]);

  useEffect(() => {
    const timer = setInterval(() => {
      window.addEventListener('scroll', handleScroll);
    }, 100);
    return () => {
      clearInterval(timer);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.scrollY >= 350) {
      menuTabRef.current.style.position = 'fixed';
      menuTabRef.current.style.top = '46px';
      menuTabRef.current.style.zIndex = '100';
      menuTabRef.current.style.borderTop = '1px solid #efefef';
    } else {
      menuTabRef.current.style.position = 'relative';
      menuTabRef.current.style.top = null;
    }
  };

  return (
    <div id="main-wrapper" style={{ overflow: 'none' }}>
      <MainTemplate>
        {/* 배너 */}
        {bannerWebtoonList && bannerWebtoonList.length > 0 && (
          <OwlCarousel className="owl-theme" items={1} autoplay={true} loop margin={0} dots={false}>
            {bannerWebtoonList.map((bannerWebtoon, index) => {
              return (
                <div
                  key={index}
                  className="banner"
                  style={{
                    backgroundImage: `url(${bannerWebtoon.thumbnailUrl})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    width: '100vw',
                  }}>
                  <div className="content-wrapper">
                    <div className="title">{bannerWebtoon.title}</div>
                    <div className="description">{bannerWebtoon.description}</div>
                    <div className="line" />
                    <div className="info">
                      <div className="category">{`${bannerWebtoon.category1} · ${bannerWebtoon.category2}`}</div>
                      <div className="fr page">{`${index + 1}/${bannerWebtoonList.length}`}</div>
                    </div>
                  </div>
                </div>
              );
            })}
          </OwlCarousel>
        )}

        {/* 메뉴 탭 */}
        <div className="menu-tab" ref={menuTabRef}>
          <a className="tab" onClick={() => setSelectedTab(TAB_INDEX.RANKING)}>
            <span className="menu-title">랭킹</span>
            {selectedTab === TAB_INDEX.RANKING && <div className="indicator" />}
          </a>
          <a className="tab" onClick={() => setSelectedTab(TAB_INDEX.NEW)}>
            <span className="menu-title">신작</span>
            {selectedTab === TAB_INDEX.NEW && <div className="indicator" />}
          </a>
          <a className="tab" onClick={() => setSelectedTab(TAB_INDEX.STORAGE)}>
            <span className="menu-title">보관함</span>
            {selectedTab === TAB_INDEX.STORAGE && <div className="indicator" />}
          </a>
        </div>

        {/* 리스트 */}
        <div className="list" ref={listRef}>
          {webtoonList &&
            webtoonList.map((webtoon, index) => {
              return (
                <NavLink key={index} className="webtoon-wrapper" to={`/webtoon/${webtoon.id}`}>
                  <div className="thumbnail">
                    <img src={webtoon.thumbnailUrl} />
                  </div>
                  <div className="info">
                    <div className="index">{index + 1}</div>
                    <div className="title">{webtoon.title}</div>
                    <div className="extra">
                      <span className="writer">{webtoon.writer}</span>
                    </div>
                  </div>
                </NavLink>
              );
            })}
        </div>
      </MainTemplate>
    </div>
  );
}
