import React from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';

import '~/commons/css/reset.css';

import * as ROUTE_PATH from '~/constants/route-path';

import { Main } from '~/page/Main';
import { Webtoon } from './page/Webtoon';
import { WebtoonView } from './page/WebtoonView';

function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/webtoon/*" element={<Webtoon />} />
        <Route path="/webtoon/view/*" element={<WebtoonView />} />
      </Routes>
    </BrowserRouter>
  );
}
export default Router;
